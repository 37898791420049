import { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  cacheControl,
  filterMarkets,
  getHrefLanguages,
} from '@hultafors/shared/helpers';
import { Language } from '@hultafors/shared/types';

import { getApi, markets } from '@hultafors/eripiowear/api';
import {
  BasePageProps,
  LandingPageFragment,
} from '@hultafors/eripiowear/types';

const PageContent = dynamic(() =>
  import('@hultafors/eripiowear/components').then(
    (module) => module.PageContent
  )
);

interface LandingPageProps extends BasePageProps {
  landingPage: LandingPageFragment;
  hrefLanguages?: Language[];
}

export const getServerSideProps: GetServerSideProps<LandingPageProps> = async ({
  res,
  req,
  locale,
}) => {
  try {
    const { settings, dato } = getApi(req, locale);
    const {
      data: { landingPage, globalContent, footer, allMenuItems, site },
    } = await dato.getLandingPage();

    const hrefLanguages = getHrefLanguages(
      settings,
      filterMarkets(markets, 'ERIPIOWEAR'),
      landingPage?.pageLocales
    );

    const props: LandingPageProps = {
      allMenuItems,
      footer,
      globalContent,
      hrefLanguages,
      landingPage,
      settings,
      site,
    };

    res.setHeader('Cache-Control', cacheControl.page);
    return {
      props,
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
    };
  }
};

export const Index: NextPage<LandingPageProps> = ({
  landingPage,
  site,
  hrefLanguages,
}) => {
  const title = `${landingPage?.title || site.globalSeo.fallbackSeo.title}`;
  const description = `${
    landingPage?.description || site.globalSeo.fallbackSeo.description
  }`;
  const image = `${
    landingPage?.image?.url || site.globalSeo.fallbackSeo.image.url
  }`;

  return (
    <PageContent
      content={landingPage.content}
      title={title}
      description={description}
      image={image}
      testId="landing-page"
      hrefLanguages={hrefLanguages}
    />
  );
};

export default Index;
